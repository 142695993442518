import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ICountry } from 'src/app/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  countries: Observable<string[]>;

  constructor(private http: HttpClient) {
    this.countries = new Observable<string[]>();
    this.getCountries();
  }

  getCountries() {
    this.countries = this.http.get<[]>(environment.country_service_api).pipe(
      map(data =>
        data
          .filter((country: ICountry) => country.region === 'Europe')
          .map((country: ICountry) => country.name.common)
          .sort()
      )
    );
  }
}
