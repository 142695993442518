import { WelcomeMessageComponent } from './components/home/welcome-message/welcome-message.component';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { FormatTimestampPipe } from 'src/app/services/format-timestamp.service';

import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetDialogueComponent } from './components/login/password-reset-dialogue/password-reset-dialogue.component';
import { AngularMaterialModule } from './angular-material.module';
import { RegisterComponent } from './components/register/register.component';
import { FlightLogAddComponent } from './components/add-flight-form/add-flight-main/flight-log-add.component';
import { AddFlightLocationComponent } from './components/add-flight-form/add-flight-location/add-flight-location.component';
import { AddFlightBalloondetailsComponent } from './components/add-flight-form/add-flight-balloondetails/add-flight-balloondetails.component';
import { AddFlightFlightdetailsComponent } from './components/add-flight-form/add-flight-flightdetails/add-flight-flightdetails.component';
import { AddFlightCommentaryComponent } from './components/add-flight-form/add-flight-commentary/add-flight-commentary.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog-component/confirmation-dialog-component.component';
import { FlightLogbookComponent } from './components/flight-logbook/flight-logbook.component';
import { HttpClientModule } from '@angular/common/http';
import { FlightDetailsComponent } from './components/flight-details/flight-details.component';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { EditFlightFormComponent } from './components/edit-flight-form/edit-flight-form.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { MobileAppComponent } from './views/mobile-app/mobile-app.component';
import { DesktopAppComponent } from './views/desktop-app/desktop-app.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomeComponent } from './components/home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    MobileAppComponent,
    DesktopAppComponent,
    LoginComponent,
    RegisterComponent,
    PasswordResetDialogueComponent,
    DashboardComponent,
    HomeComponent,
    WelcomeMessageComponent,
    FlightLogAddComponent,
    AddFlightLocationComponent,
    AddFlightBalloondetailsComponent,
    AddFlightFlightdetailsComponent,
    AddFlightCommentaryComponent,
    FlightLogbookComponent,
    FlightDetailsComponent,
    ConfirmationDialogComponent,
    ProfileComponent,
    NavBarComponent,
    NotificationsComponent,
    EditFlightFormComponent,
    FormatTimestampPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthService,
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ['http://127.0.0.1:9099'] : undefined
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ['http://127.0.0.1:9000'] : undefined
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['127.0.0.1', '8080'] : undefined
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['http://127.0.0.1:5001'] : undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
