import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IFlightLog } from '../../models/flight-log.model';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private http: HttpClient,
    private firestoreAngular: AngularFirestore
  ) {}

  async addFlightLog(flightLog: IFlightLog, userId: string) {
    await this.firestoreAngular
      .collection('pilots')
      .doc(userId)
      .collection('logs')
      .add(flightLog);
    return true;
  }

  getLogsForUser(
    userId: string
  ): Observable<DocumentChangeAction<IFlightLog>[]> {
    const logsCollection: AngularFirestoreCollection<IFlightLog> =
      this.firestoreAngular.collection('pilots').doc(userId).collection('logs');
    return logsCollection.snapshotChanges();
  }

  async updateLog(flightLog: IFlightLog, userId: string) {
    await this.firestoreAngular
      .collection('pilots')
      .doc(userId)
      .collection('logs')
      .doc(flightLog.id)
      .update(flightLog);
    return true;
  }

  deleteLog(log: IFlightLog, userId: string) {
    this.firestoreAngular
      .collection('pilots')
      .doc(userId.toString())
      .collection('logs')
      .doc(log.id)
      .delete();
  }
}
