<div class="card-grid">
  <h1>Flight Number: {{ logData.flightNumber }}</h1>

  <mat-card>
    <mat-card-content>
      <h2>Balloon - Airship</h2>
      <hr />
      <p><strong>Balloon Type:</strong> {{ logData.balloonType }}</p>
      <p>
        <strong>Balloon Registration:</strong> {{ logData.balloonRegistration }}
      </p>
      <p><strong>Balloon Group:</strong> {{ logData.balloonGroup }}</p>
      <p><strong>Pilot in Command:</strong> {{ logData.pilotInCommand }}</p>
      <p><strong>Type of Operation:</strong> {{ logData.typeOfOperation }}</p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <h2>Flight</h2>
      <hr />
      <h3>Departure</h3>
      <p>
        <strong>From:</strong>
        {{
          logData.selectedMunicipalityTakeOff +
            ', ' +
            logData.selectedCountryTakeOff
        }}
      </p>
      <p>
        <strong>Departure Date/Time:</strong>
        <br />
        {{ logData.departureDateTime | formatTimestamp: 'DD/MM/YYYY HH:mm:ss' }}
      </p>
      <h3>Arrival</h3>
      <p>
        <strong>From:</strong>
        {{
          logData.selectedMunicipalityLanding +
            ', ' +
            logData.selectedCountryLanding
        }}
      </p>
      <p>
        <strong>Arrival Date/Time: </strong>
        <br />
        {{ logData.arrivalDateTime | formatTimestamp: 'DD/MM/YYYY HH:mm:ss' }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <h2>Extra Information</h2>
      <hr />
      <p><strong>Extra Commentary:</strong> {{ logData.extraCommentary }}</p>
      <p>
        <strong>Instructor/Examiner Name:</strong>
        {{ logData.instructorExaminerName }}
      </p>
      <p><strong>Amount of Landings:</strong> {{ logData.amountOfLandings }}</p>
      <p>
        <strong>Type of Flight Options:</strong>
        {{ logData.typeOfFlightOptions }}
      </p>
      <p><strong>Pilot Type:</strong> {{ logData.pilotType }}</p>
      <p><strong>Verified:</strong> {{ logData.verified ? 'Yes' : 'No' }}</p>
    </mat-card-content>
  </mat-card>
</div>
