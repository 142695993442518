import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { IBalloon } from 'src/app/models/balloon.model';

@Injectable({
  providedIn: 'root'
})
export class BalloonService {
  constructor(
    private firestoreAngular: AngularFirestore,
    private authService: AuthService
  ) {}

  getBalloons(): Observable<DocumentChangeAction<IBalloon>[]> {
    return this.authService.getCurrentUserID().pipe(
      switchMap(userId => {
        const balloonsCollection: AngularFirestoreCollection<IBalloon> =
          this.firestoreAngular.collection(`pilots/${userId}/balloons`);
        return balloonsCollection.snapshotChanges();
      })
    );
  }
}
