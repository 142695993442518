import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-flight-commentary',
  templateUrl: './add-flight-commentary.component.html',
  styleUrls: ['./add-flight-commentary.component.css']
})
export class AddFlightCommentaryComponent {
  @Input() flightForm!: FormGroup;
}
