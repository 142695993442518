<h2 mat-dialog-title>Reset password!</h2>
<mat-dialog-content>
  <form [formGroup]="resetPasswordForm">
    <mat-form-field appearance="outline" class="input-field">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="email" required />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>

  <button
    mat-button
    [mat-dialog-close]="true"
    (click)="forgotPassword()"
    [disabled]="resetPasswordForm.invalid">
    Send
  </button>
</mat-dialog-actions>
