import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PasswordResetDialogueComponent } from './password-reset-dialogue/password-reset-dialogue.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogRef: MatDialog
  ) {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  async loginUser() {
    try {
      await this.authService.login(
        this.loginForm.value.email,
        this.loginForm.value.password
      );
      await this.authService.GetUserFromFirestore().subscribe();
    } catch (error) {
      this.loginForm.reset();
    }
  }

  openDialog() {
    this.dialogRef.open(PasswordResetDialogueComponent);
  }

  handleBalloonClick(event: MouseEvent, direction: string) {
    const currentBalloon = event.target as HTMLElement;

    // Toggle the respective animation classes for left and right movement
    if (direction === 'left') {
      currentBalloon.classList.toggle('animate-left');
    } else if (direction === 'right') {
      currentBalloon.classList.toggle('animate-right');
    }

    // Remove the classes after the animation ends
    currentBalloon.addEventListener(
      'animationend',
      () => {
        currentBalloon.classList.remove('animate-left', 'animate-right');
      },
      { once: true }
    );
  }
}
