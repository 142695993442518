<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<mat-toolbar class="toolbar">
  <span class="logbookTitle">Flight Logbook</span>
  <button mat-icon-button routerLink="/addlog">
    <mat-icon>add</mat-icon>
  </button>
  <span class="sortDropdown">
    <mat-select
      [(ngModel)]="sortingOrder"
      (ngModelChange)="sortLogsByFlightNumber()">
      <mat-option value="asc">Low to High</mat-option>
      <mat-option value="desc">High to Low</mat-option>
    </mat-select>
  </span>
  <span class="logoutButton">
    <button mat-button (click)="logOut()">Logout</button>
  </span>
</mat-toolbar>

<div class="log-card-container">
  <mat-card
    *ngFor="let log of logs; let last = last"
    [ngClass]="{ 'log-card-last': last }"
    class="log-card">
    <mat-card-header class="center-text">
      <div class="icon-container">
        <span
          class="material-symbols-outlined verified"
          *ngIf="log.verified === true">
          verified_user
        </span>
        <span class="material-symbols-outlined" *ngIf="log.verified === false">
          shield
        </span>
      </div>
      <mat-card-title class="mat-card-title">
        <div class="top-left">
          {{ log.flightNumber }}
        </div>

        <div>
          {{ log.departureDateTime | formatTimestamp: 'DD/MM/YYYY HH:mm:ss' }}
        </div>

        <div class="seperator">-</div>

        <div>
          {{ log.balloonRegistration }}
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        <div>{{ log.balloonType }} - Group {{ log.balloonGroup }}</div>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="center-text">
      <p>
        {{ log.selectedMunicipalityTakeOff | uppercase }} -
        {{ log.selectedMunicipalityLanding | uppercase }}
      </p>
      <p>
        {{ log.departureDateTime | formatTimestamp: 'HH:mm' }} -
        {{ log.arrivalDateTime | formatTimestamp: 'HH:mm' }}
      </p>
      <p>PIC: {{ log.pilotInCommand | uppercase }}</p>
    </mat-card-content>

    <mat-card-actions>
      <span class="button-container"
        ><button mat-raised-button class="button" (click)="openLogDetails(log)">
          More
        </button>

        <!-- Use ngIf to show delete button only for the last log -->
        <button
          mat-raised-button
          class="delete-button"
          *ngIf="isLastLog(log) && arrivalDateMonthCheck(log)"
          (click)="openConfirmationDialog(log)">
          Delete
        </button>
        <button
          mat-raised-button
          class="edit-button"
          *ngIf="isLastLog(log)"
          (click)="openEditDialog(log)">
          Edit
        </button>
      </span>
    </mat-card-actions>
  </mat-card>
</div>
