<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<mat-toolbar class="toolbar">Profile</mat-toolbar>

<div class="profile-container">
  <div class="profile">
    <div class="icon-container">
      <span class="material-symbols-outlined verified" *ngIf="userIsCurrent">
        verified_user
      </span>
      <span class="material-symbols-outlined" *ngIf="!userIsCurrent">
        shield
      </span>
    </div>

    <span class="material-symbols-outlined icon">account_circle</span>

    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>License Number</mat-label>
        <input matInput formControlName="licenseNumber" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Issuing Country</mat-label>
        <select matNativeControl formControlName="issuingCountry" required>
          <option
            *ngFor="let country of countryService.countries | async"
            [value]="country">
            {{ country }}
          </option>
        </select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
      </mat-form-field>

      <button mat-button type="submit">Save</button>
    </form>
  </div>
</div>
