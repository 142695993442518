import { Component } from '@angular/core';
import { NotificationService } from './../../services/notification.service/notification.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
  notifications: any[] = [];
  todayDate: number = new Date().getTime() / 1000;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getCurrentUserID().subscribe(userID => {
      if (userID) {
        this.notificationService
          .getNotificationsForUser(userID)
          .subscribe(notifications => {
            this.notifications = notifications.map(notification => {
              const notificationData = notification.payload.doc.data();
              notificationData.id = notification.payload.doc.id;
              return notificationData;
            });
          });
      }
    });
  }

  logOut() {
    this.authService.logOut();
  }

  calculateDifference(date: any) {
    const difference = date.seconds - this.todayDate;
    return Math.floor(difference / (3600 * 24));
  }
}
