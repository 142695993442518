import { Injectable } from '@angular/core';
import {
  DocumentChangeAction,
  AngularFirestoreCollection,
  AngularFirestore
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { IFlightLog } from 'src/app/models/flight-log.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private angularFirestore: AngularFirestore) {}

  getNotificationsForUser(
    userId: string
  ): Observable<DocumentChangeAction<IFlightLog>[]> {
    const logsCollection: AngularFirestoreCollection<IFlightLog> =
      this.angularFirestore
        .collection('pilots')
        .doc(userId)
        .collection('notifications');
    return logsCollection.snapshotChanges();
  }
}
