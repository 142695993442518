import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { IPilot } from '../../models/pilot.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryService } from 'src/app/services/country.service/country.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  countryService: CountryService;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private _countryService: CountryService
  ) {
    this.registerForm = this.fb.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      licenseNumber: new FormControl('', Validators.required),
      issuingCountry: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(20)])
      ),
      password: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
      confirmPassword: new FormControl(
        '',
        Validators.compose([this.passwordsMatching])
      )
    });
    this.countryService = _countryService;
  }

  ngOnInit(): void {}

  async registerUser() {
    if (this.registerForm.invalid) return;

    const pilot: IPilot = {
      email: this.registerForm.value.email,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName,
      issuingCountry: this.registerForm.value.issuingCountry,
      licenseNumber: this.registerForm.value.licenseNumber
    };

    try {
      await this.authService.register(pilot, this.registerForm.value.password);
    } catch {
      this.registerForm.reset();
    }
  }

  passwordsMatching: ValidatorFn = (
    form: AbstractControl
  ): ValidationErrors | null => {
    if (form.value !== this.registerForm?.value.password) {
      return { passwordNotMatching: true };
    } else {
      return null;
    }
  };
}
