import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log.service/log.service';
import { IFlightLog } from 'src/app/models/flight-log.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FlightDetailsComponent } from '../flight-details/flight-details.component';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog-component/confirmation-dialog-component.component';
import { EditFlightFormComponent } from '../edit-flight-form/edit-flight-form.component';
@Component({
  selector: 'app-flight-logbook',
  templateUrl: './flight-logbook.component.html',
  styleUrls: ['./flight-logbook.component.css']
})
export class FlightLogbookComponent implements OnInit {
  displayedColumns: string[] = [
    'flightNumber',
    'balloonRegistration',
    'pilotInCommand',
    'typeOfOperation',
    'flightType',
    'selectedMunicipalityTakeOff',
    'selectedMunicipalityLanding',
    'departureDateTime',
    'arrivalDateTime',
    'instructorExaminerName',
    'verified'
  ];
  logs: IFlightLog[] = [];
  sortingOrder: 'asc' | 'desc' = 'asc';

  constructor(
    private logService: LogService,
    private authService: AuthService,
    private dialogRef: MatDialog,
    private router: Router,
    private confirmationDialog: MatDialog
  ) {}

  ngOnInit() {
    this.authService.getCurrentUserID().subscribe(userID => {
      if (userID) {
        this.logService.getLogsForUser(userID).subscribe(logs => {
          this.logs = [];

          logs.map(log => {
            const logData = log.payload.doc.data();
            logData.id = log.payload.doc.id;
            this.logs.push(logData);
          });
          this.sortLogsByFlightNumber();
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  logOut() {
    this.authService.logOut();
  }

  sortLogsByFlightNumber() {
    this.logs.sort((a, b) => {
      const flightNumberA = a.flightNumber;
      const flightNumberB = b.flightNumber;

      if (this.sortingOrder === 'asc') {
        return flightNumberA - flightNumberB;
      } else {
        return flightNumberB - flightNumberA;
      }
    });
  }

  openLogDetails(log: IFlightLog) {
    this.dialogRef.open(FlightDetailsComponent, {
      width: '80%',
      height: '80%',
      data: { logData: log }
    });
  }

  isLastLog(log: IFlightLog): boolean {
    // Find the log with the highest flight number
    const maxFlightNumber = Math.max(...this.logs.map(log => log.flightNumber));
    // Check if the provided log has the highest flight number
    return log.flightNumber === maxFlightNumber;
  }

  arrivalDateMonthCheck(log: IFlightLog): boolean {
    const arrivalDate = (log.arrivalDateTime as any).toDate();

    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setMonth(currentDate.getMonth() - 1);

    return arrivalDate > pastDate && arrivalDate <= currentDate;
  }

  deleteLog(log: IFlightLog) {
    this.authService.getCurrentUserID().subscribe(userId => {
      if (userId) {
        this.logService.deleteLog(log, userId);
        this.router.navigate(['/logbook']);
      } else {
        // Handle scenario where no user is logged in
        console.error('not logged in');
      }
    });
  }

  openEditDialog(log: IFlightLog) {
    const dialogRef = this.dialogRef.open(EditFlightFormComponent, {
      width: '95%', // Adjust the width as needed
      data: { log }
    });
  }

  openConfirmationDialog(log: IFlightLog): void {
    const dialogRef = this.dialogRef.open(ConfirmationDialogComponent, {
      width: '50%', // Adjust the width as needed
      data: { log }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteLog(log);
      }
    });
  }
}
