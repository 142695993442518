import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { FlightLogAddComponent } from './components/add-flight-form/add-flight-main/flight-log-add.component';
import { FlightLogbookComponent } from './components/flight-logbook/flight-logbook.component';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/compat/auth-guard';
import { ProfileComponent } from './components/profile/profile.component';
import { MobileAppComponent } from './views/mobile-app/mobile-app.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

const redirectUnauthorized = () => redirectUnauthorizedTo(['/']);
const redirectLoggedIn = () => redirectLoggedInTo(['/logbook']);

const routes: Routes = [
  {
    path: '',
    component: MobileAppComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedIn }
      },
      { path: 'register', component: RegisterComponent },
      {
        path: '',
        component: DashboardComponent,
        data: { authGuardPipe: redirectUnauthorized },
        canActivate: [AngularFireAuthGuard],
        children: [
          {
            path: 'home',
            component: HomeComponent
          },
          {
            path: 'logbook',
            component: FlightLogbookComponent
          },
          {
            path: 'profile',
            component: ProfileComponent
          },
          {
            path: 'notifications',
            component: NotificationsComponent
          }
        ]
      },
      {
        path: 'addlog',
        component: FlightLogAddComponent,
        data: { authGuardPipe: redirectUnauthorized },
        canActivate: [AngularFireAuthGuard]
      }
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
