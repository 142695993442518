<img id="cloud1" src="../../../assets/images/cloud.png" alt="" />
<img id="cloud2" src="../../../assets/images/cloud.png" alt="" />
<img id="cloud3" src="../../../assets/images/cloud.png" alt="" />

<div class="register-container mat-elevation-z8">
  <form
    [formGroup]="registerForm"
    class="register-form"
    (ngSubmit)="registerUser()">
    <h2>Register</h2>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>License Number</mat-label>
      <input matInput formControlName="licenseNumber" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Issuing Country</mat-label>
      <select matNativeControl formControlName="issuingCountry" required>
        <option
          *ngFor="let country of countryService.countries | async"
          [value]="country">
          {{ country }}
        </option>
      </select>
      <mat-error>Please select a country!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="email" required />
      <mat-error>Please enter a valid email!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" required />
      <mat-error>Please enter a valid password!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="confirmPassword"
        required />
      <mat-error>Passwords should match!</mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="registerForm.invalid">
      Register
    </button>
    <button mat-button color="primary" routerLink="/" type="button">
      Already have an account?
    </button>
  </form>
</div>
