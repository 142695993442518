<mat-toolbar class="toolbar">
  <span class="spacer"></span>
  <span class="toolbar-title">Home</span>
  <span class="spacer"></span>
  <span class="logoutButton">
    <button mat-button (click)="authservice.logOut()">Logout</button>
  </span>
</mat-toolbar>

<div class="container">
  <app-welcome-message [username]="getUsername()"></app-welcome-message>

  <mat-card class="time-card">
    <mat-card-content>
      <div class="time">{{ currentDateAndTime | date: 'HH:mm:ss' }}</div>
      <div class="date">{{ currentDateAndTime | date: 'dd MMMM yyyy' }}</div>
    </mat-card-content>
  </mat-card>

  <div class="bottom-buttons">
    <button mat-raised-button color="primary" routerLink="/addlog">
      Create Flight Log
    </button>

    <button mat-raised-button color="accent">Am I Allowed to Fly?</button>
  </div>
</div>
