import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  authservice: AuthService;
  currentDateAndTime: string;

  constructor(private _authService: AuthService) {
    this.authservice = _authService;
    this.updateDateTime();
    setInterval(() => {
      this.updateDateTime();
    }, 1000);
    this.currentDateAndTime = '';
  }

  getUsername(): string {
    return this.authservice.getCachedUserData()?.firstName ?? '';
  }

  updateDateTime(): void {
    const currentDateTime = new Date();
    this.currentDateAndTime = currentDateTime.toISOString();
  }
}
