import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-welcome-message',
  template: `<span [innerHTML]="formattedMessage"></span>`,
  styleUrls: ['./welcome-message.component.css']
})
export class WelcomeMessageComponent {
  @Input() username = '';

  private welcomeMessages: string[] = [
    "Welcome, [Username]! Let's soar high!",
    '[Username], float into memories!',
    'Hi [Username]! Up, up, and away!',
    'Hello [Username]! Ready to explore?',
    "Greetings, [Username]! Let's take flight!",
    'Hey there, [Username]! Adventure awaits!',
    "Welcome back, [Username]! Let's fly!",
    "Ahoy, [Username]! Let's sail the skies!",
    '[Username], rise up and enjoy the view!',
    "Bon voyage, [Username]! Let's begin!",
    "[Username], let's journey among the clouds!",
    '[Username], buckle up for an aerial journey!',
    'Hi there, [Username]! Ready to take off?',
    "[Username], let's make memories in the sky!",
    "Welcome aboard, [Username]! Let's take flight!",
    '[Username], your balloon adventure starts now!',
    "Up, up, and away, [Username]! Let's embark!",
    "[Username], the sky's the limit! Let's go!",
    "Hello, [Username]! Let's drift into new memories!",
    "[Username], let's paint the sky with memories!",
    "Greetings, [Username]! Let's elevate experiences!",
    "Hi [Username]! Let's create a sky full of stories!",
    '[Username], time to elevate your adventures!',
    '[Username], welcome to your balloon odyssey!',
    "Hey [Username]! Let's float through memories together!",
    "Welcome, [Username]! Let's fly into new horizons!",
    '[Username], prepare for a journey above and beyond!',
    "Hello, [Username]! Let's script stories in the sky!",
    "[Username], let's drift through dreamy experiences!"
  ];

  formattedMessage = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateMessage();
  }

  updateMessage() {
    const randomIndex = Math.floor(Math.random() * this.welcomeMessages.length);
    const message = this.welcomeMessages[randomIndex].replace(
      '[Username]',
      `<span class="username">${this.username}</span>`
    );
    this.formattedMessage = message;
    this.cdr.detectChanges();
  }
}
