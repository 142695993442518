import { AngularFireAuth } from '@angular/fire/compat/auth/';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IPilot } from '../models/pilot.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';
import { FirebaseError } from '@angular/fire/app';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private cachedUserSubject: BehaviorSubject<IPilot | null> =
    new BehaviorSubject<IPilot | null>(null);
  public cachedUser$: Observable<IPilot | null> =
    this.cachedUserSubject.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private firestoreAngular: AngularFirestore,
    private snackBarService: SnackbarService
  ) {}

  async register(pilot: IPilot, password: string) {
    try {
      const credentials = await this.afAuth.createUserWithEmailAndPassword(
        pilot.email,
        password
      );
      if (credentials.user) {
        await this.firestoreAngular
          .collection('pilots')
          .doc(credentials.user.uid)
          .set(pilot)
          .then(() => {
            this.snackBarService.openSnackBar('Registration Completed', '');
          });
        this.router.navigate(['addlog']);

        return credentials;
      } else {
        throw new Error('Registration failed.');
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        this.snackBarService.openSnackBar(
          'Registration Failed: ' + error.code.split('/')[1],
          ''
        );
      }
      throw error;
    }
  }

  async logOut() {
    await this.afAuth.signOut();
    this.router.navigate(['login']);
  }

  async login(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.snackBarService.openSnackBar('Login Successful', 'Ok');
      this.router.navigate(['home']);
    } catch (error) {
      if (error instanceof FirebaseError) {
        this.snackBarService.openSnackBar(
          'Login Failed: ' + error.code.split('/')[1],
          ''
        );
      }
      throw error;
    }
  }

  async resetPassword(email: string) {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.snackBarService.openSnackBar('Password Reset Email Sent', 'Ok');
      })
      .catch(error => {
        this.snackBarService.openSnackBar(
          'Password Reset Failed: ' + error.code.split('/')[1] + '!',
          'Ok'
        );
      });
  }

  getCurrentUserID(): Observable<string | null> {
    return this.afAuth.authState.pipe(
      map(user => {
        if (user) {
          return user.uid;
        } else {
          return null;
        }
      })
    );
  }

  GetUserFromFirestore(): Observable<IPilot | null> {
    return this.getCurrentUserID().pipe(
      switchMap((userId: string | null) => {
        if (userId) {
          const cachedUser = this.getCachedUserData();
          if (cachedUser) {
            return of(cachedUser);
          } else {
            return this.firestoreAngular
              .collection('pilots')
              .doc(userId)
              .valueChanges() as Observable<IPilot | null>;
          }
        } else {
          return of(null);
        }
      }),
      tap((userData: IPilot | null) => {
        // Cache the user data after fetching
        if (userData) {
          this.setCachedUserData(userData);
        }
      })
    );
  }

  updateUser(userData: Partial<IPilot>): Promise<void> {
    return this.getCurrentUserID()
      .pipe(
        switchMap(userId => {
          if (userId) {
            return this.firestoreAngular
              .collection('pilots')
              .doc(userId)
              .update(userData)
              .then(() => {
                // Clear cached data after update
                this.cachedUserSubject.next(null);
                this.snackBarService.openSnackBar(
                  'Profile Updated Successfully',
                  ''
                );
              })
              .catch(error => {
                this.snackBarService.openSnackBar('Profile Update Failed', '');
                throw error;
              });
          } else {
            throw new Error('User ID not available');
          }
        })
      )
      .toPromise();
  }

  getCachedUserData(): IPilot | null {
    return this.cachedUserSubject.getValue();
  }

  setCachedUserData(user: IPilot | null) {
    this.cachedUserSubject.next(user);
  }
}
