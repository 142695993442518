import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFlightLog } from 'src/app/models/flight-log.model';
@Component({
  selector: 'app-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.css']
})
export class FlightDetailsComponent {
  logData: IFlightLog;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { logData: IFlightLog }) {
    this.logData = data.logData;
  }
}
