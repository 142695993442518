import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryService } from '../../../services/country.service/country.service';
import { AuthService } from 'src/app/services/auth.service';
import { IPilot } from 'src/app/models/pilot.model';

@Component({
  selector: 'app-add-flight-location',
  templateUrl: './add-flight-location.component.html',
  styleUrls: ['./add-flight-location.component.css']
})
export class AddFlightLocationComponent implements OnInit {
  @Input() flightForm1!: FormGroup;
  countryService: CountryService;

  constructor(
    private _countryService: CountryService,
    private authService: AuthService
  ) {
    this.countryService = _countryService;
  }

  ngOnInit(): void {
    //this sets the date automaticly to todays date. (this works but makes tests fail.)
    //this.flightForm.get('departureDateTime')?.setValue(new Date());
    //this.flightForm.get('arrivalDateTime')?.setValue(new Date());

    this.flightForm1
      .get('selectedCountryTakeOff')
      ?.valueChanges.subscribe(takeOffCountry => {
        this.flightForm1
          .get('selectedCountryLanding')!
          .setValue(takeOffCountry);

        /* this.authService
          .GetUserFromFirestore()
          .subscribe((user: IPilot | null) => {
            if (user) {
              this.flightForm1
                .get('selectedCountryTakeOff')
                ?.setValue(user.issuingCountry);
            }
          }); */
      });
  }

  onTakeOffCountryChange(event: any) {
    const takeOffCountry = event.target.value;
    this.flightForm1.get('selectedCountryLanding')!.setValue(takeOffCountry);
  }

  onArrivalDateChange(event: any) {
    const departureDateValue = this.flightForm1.get('departureDateTime')!.value;
    this.flightForm1.patchValue({ arrivalDateTime: departureDateValue });
  }
}
