import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BalloonGroup, IBalloon } from 'src/app/models/balloon.model';
import { BalloonService } from 'src/app/services/balloon.service/balloon.service';

@Component({
  selector: 'app-add-flight-balloondetails',
  templateUrl: './add-flight-balloondetails.component.html',
  styleUrls: ['./add-flight-balloondetails.component.css']
})
export class AddFlightBalloondetailsComponent {
  @Input() flightForm!: FormGroup;
  options: { id: string; doc: IBalloon }[] = [];
  balloonGroups = Object.values(BalloonGroup);

  constructor(private balloonService: BalloonService) {
    this.balloonService.getBalloons().subscribe(balloons => {
      balloons.forEach(balloon => {
        this.options.push({
          id: balloon.payload.doc.id,
          doc: balloon.payload.doc.data()
        });
      });
    });
  }

  selectOption(option: { id: string; doc: IBalloon }) {
    this.flightForm.patchValue(option.doc);
  }
}
