<div class="form-container">
  <form [formGroup]="editForm">
    <div class="section">
      <span>
        <img
          src="\..\..\..\assets\images\flight_takeoff_FILL0_wght400_GRAD0_opsz24.svg"
          alt="alt" />
      </span>
      <h3>Take-off</h3>
    </div>
    <mat-form-field>
      <mat-label>Select the take-off country:</mat-label>
      <input matInput formControlName="selectedCountryTakeOff" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Enter municipality:</mat-label>
      <input matInput formControlName="selectedMunicipalityTakeOff" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Departure date:</mat-label>
      <input
        matInput
        [matDatepicker]="departurePicker"
        formControlName="departureDateTime"
        required />
      <mat-datepicker-toggle
        matSuffix
        [for]="departurePicker"></mat-datepicker-toggle>
      <mat-datepicker #departurePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <label for="departureTime">Departure Time:</label>
      <input
        matInput
        formControlName="departureTime"
        type="time"
        id="departureTime"
        name="departureTime"
        required />
    </mat-form-field>

    <hr class="separator" />

    <div class="section">
      <span>
        <img
          src="\..\..\..\assets\images\flight_land_FILL0_wght400_GRAD0_opsz24.svg"
          alt="alt" />
      </span>
      <h3>Landing</h3>
    </div>
    <mat-form-field>
      <mat-label>Select the landing country:</mat-label>
      <input matInput formControlName="selectedCountryLanding" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Enter municipality:</mat-label>
      <input
        matInput
        formControlName="selectedMunicipalityLanding"
        placeholder="Enter municipality name"
        required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Arrival date:</mat-label>
      <input
        matInput
        [matDatepicker]="arrivalPicker"
        formControlName="arrivalDateTime" />
      <mat-datepicker-toggle
        matSuffix
        [for]="arrivalPicker"></mat-datepicker-toggle>
      <mat-datepicker #arrivalPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <label for="arrivalTime">Arrival Time:</label>
      <input
        matInput
        formControlName="arrivalTime"
        type="time"
        id="arrivalTime"
        name="arrivalTime"
        required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Balloon Registration Number:</mat-label>
      <input matInput formControlName="balloonRegistration" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Balloon Group:</mat-label>
      <input matInput formControlName="balloonGroup" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Balloon Type:</mat-label>
      <input matInput formControlName="balloonType" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Amount of Landings:</mat-label>
      <select
        matNativeControl
        formControlName="amountOfLandings"
        placeholder="Select Landings">
        <option *ngFor="let landing of [0, 1, 2, 3, 4, 5]" [value]="landing">
          {{ landing }}
        </option>
      </select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Type of Operation:</mat-label>
      <input matInput type="text" formControlName="typeOfOperation" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Pilot in Command:</mat-label>
      <input matInput formControlName="pilotInCommand" />
    </mat-form-field>
    <br />
    <mat-radio-group formControlName="pilotType">
      <mat-label>Pilot Type:</mat-label>
      <br />
      <mat-radio-button [value]="'No instructor/examiner'"
        >No instructor/examiner</mat-radio-button
      >
      <mat-radio-button [value]="'Flight instructor'"
        >Flight instructor</mat-radio-button
      >
      <mat-radio-button [value]="'Flight examiner'"
        >Flight examiner</mat-radio-button
      >
    </mat-radio-group>

    <mat-form-field
      *ngIf="
        editForm.get('pilotType')?.value === 'Flight instructor' ||
        editForm.get('pilotType')?.value === 'Flight examiner'
      ">
      <mat-label>Instructor/Examiner Name:</mat-label>
      <input matInput formControlName="instructorExaminerName" />
    </mat-form-field>

    <mat-form-field>
      <mat-label for="extraCommentary">Extra Commentary:</mat-label>
      <textarea
        matInput
        rows="8"
        id="extraCommentary"
        formControlName="extraCommentary">
      </textarea>
    </mat-form-field>
    <button
      mat-raised-button
      (click)="saveForm()"
      [disabled]="editForm.valid === false">
      Save log
    </button>
  </form>
</div>
