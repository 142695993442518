<mat-toolbar class="toolbar">
  <span class="notification-title">Notifications</span>
  <span class="logoutButton">
    <button mat-button (click)="logOut()">Logout</button>
  </span>
</mat-toolbar>

<div *ngIf="notifications && notifications.length > 0">
  <div
    *ngFor="let notification of notifications; let last = last"
    [ngClass]="{ 'notification-card-last': last }"
    class="notification-card-container">
    <mat-card
      class="notification-card"
      *ngFor="let classKey of notification.validUntil | keyvalue">
      <mat-card-title>
        <p>Class: {{ classKey.key }}</p>
      </mat-card-title>
      <mat-card-content>
        <p>Valid Until:</p>
        <p>{{ classKey.value | formatTimestamp: 'DD/MM/YYYY' }}</p>
        <p>Days Left Until Experation:</p>
        <p>{{ calculateDifference(classKey.value) }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div
  *ngIf="!notifications || notifications.length === 0"
  class="notification-card-container">
  <mat-card class="notification-card">
    <mat-card-content>
      <p>No Notifications</p>
    </mat-card-content>
  </mat-card>
</div>
