import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password-reset-dialogue',
  templateUrl: './password-reset-dialogue.component.html',
  styleUrls: ['./password-reset-dialogue.component.css']
})
export class PasswordResetDialogueComponent implements OnInit {
  resetPasswordForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.resetPasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {}

  async forgotPassword() {
    try {
      await this.authService.resetPassword(this.resetPasswordForm.value.email);
    } catch (error) {
      console.error('Error during login: ', error);
    }
  }
}
