<form [formGroup]="flightForm">
  <mat-form-field>
    <mat-label>Balloon Registration Number:</mat-label>
    <input
      matInput
      formControlName="balloonRegistration"
      [matAutocomplete]="balloonRegistrationAuto"
      required />

    <mat-autocomplete #balloonRegistrationAuto="matAutocomplete">
      <mat-option
        *ngFor="let option of options"
        [value]="option.id"
        (onSelectionChange)="selectOption(option)">
        {{ option.id }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Balloon Group:</mat-label>
    <mat-select formControlName="balloonGroup">
      <mat-option
        *ngFor="let balloonGroup of balloonGroups"
        [value]="balloonGroup">
        {{ balloonGroup }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Balloon Type:</mat-label>
    <input matInput formControlName="balloonType" required />
  </mat-form-field>
</form>
