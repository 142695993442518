<form [formGroup]="flightForm">
  <mat-form-field>
    <mat-label>Amount of Landings:</mat-label>
    <select
      matNativeControl
      formControlName="amountOfLandings"
      placeholder="Select Landings">
      <option *ngFor="let landing of [0, 1, 2, 3, 4, 5]" [value]="landing">
        {{ landing }}
      </option>
    </select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Type of Operation:</mat-label>
    <input matInput type="text" formControlName="typeOfOperation" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Pilot in Command:</mat-label>
    <input matInput formControlName="pilotInCommand" />
  </mat-form-field>

  <mat-radio-group formControlName="pilotType">
    <mat-label>Pilot Type:</mat-label>
    <mat-radio-button [value]="'No instructor/examiner'"
      >No instructor/examiner</mat-radio-button
    >
    <mat-radio-button [value]="'Flight instructor'"
      >Flight instructor</mat-radio-button
    >
    <mat-radio-button [value]="'Flight examiner'"
      >Flight examiner</mat-radio-button
    >
  </mat-radio-group>

  <mat-form-field
    *ngIf="
      flightForm.get('pilotType')?.value === 'Flight instructor' ||
      flightForm.get('pilotType')?.value === 'Flight examiner'
    ">
    <mat-label>Instructor/Examiner Name:</mat-label>
    <input matInput formControlName="instructorExaminerName" />
  </mat-form-field>
</form>
