import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  snackBar: MatSnackBar;
  duration = 5000;

  constructor(matSnackBar: MatSnackBar) {
    this.snackBar = matSnackBar;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: this.duration
    });
  }
}
