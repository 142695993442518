export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBYbPsBcO6mvFv1_lTQLQ5J1qKAqum9YFg',
    authDomain: 'flite-dev-8f7bd.firebaseapp.com',
    projectId: 'flite-dev-8f7bd',
    storageBucket: 'flite-dev-8f7bd.appspot.com',
    messagingSenderId: '694650481229',
    appId: '1:694650481229:web:0740047b43f15414930a04'
  },
  useEmulators: false,
  country_service_api: 'https://restcountries.com/v3.1/all'
};
