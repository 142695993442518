import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { IPilot } from 'src/app/models/pilot.model';

@Component({
  selector: 'app-add-flight-flightdetails',
  templateUrl: './add-flight-flightdetails.component.html',
  styleUrls: ['./add-flight-flightdetails.component.css']
})
export class AddFlightFlightdetailsComponent implements OnInit {
  @Input() flightForm!: FormGroup;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.GetUserFromFirestore().subscribe((user: IPilot | null) => {
      if (user) {
        this.flightForm
          .get('pilotInCommand')
          ?.setValue(user.firstName + ' ' + user.lastName);
      }
    });
  }
}
