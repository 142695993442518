<img
  id="balloon1"
  class="balloon"
  (click)="handleBalloonClick($event, 'left')"
  src="../../../assets/images/balloon.png"
  alt="" />
<img
  id="balloon2"
  class="balloon"
  (click)="handleBalloonClick($event, 'right')"
  src="../../../assets/images/balloon.png"
  alt="" />
<img
  id="balloon3"
  class="balloon"
  (click)="handleBalloonClick($event, 'left')"
  src="../../../assets/images/balloon.png"
  alt="" />
<img id="cloud1" src="../../../assets/images/cloud.png" alt="" />
<img id="cloud2" src="../../../assets/images/cloud.png" alt="" />
<img id="cloud3" src="../../../assets/images/cloud.png" alt="" />

<div class="login-container mat-app-background mat-elevation-z8">
  <form [formGroup]="loginForm" class="login-form" (ngSubmit)="loginUser()">
    <div class="mat-app-text">
      <h1>Welcome Back!</h1>
      <p>Did you enjoy your flight?</p>
    </div>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label class="mat-app-text">E-mail</mat-label>
      <input matInput formControlName="email" required />
      <mat-error>Please enter a valid email!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label class="mat-app-text">Password</mat-label>
      <input matInput type="password" formControlName="password" required />
      <mat-hint>
        <button
          mat-button
          color="primary"
          class="mat-app-text"
          (click)="openDialog()"
          type="button">
          Forgot password?
        </button>
      </mat-hint>
    </mat-form-field>

    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="loginForm.invalid">
      Continue with email
    </button>
    <button mat-button color="primary" routerLink="/register" type="button">
      Register
    </button>
  </form>
</div>
