<form [formGroup]="flightForm">
  <mat-form-field>
    <mat-label for="extraCommentary">Extra Commentary:</mat-label>
    <textarea
      matInput
      rows="8"
      id="extraCommentary"
      formControlName="extraCommentary">
    </textarea>
  </mat-form-field>
</form>
