import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { IPilot } from '../../models/pilot.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountryService } from 'src/app/services/country.service/country.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  user: IPilot | null = null;
  userDataSubscription: Subscription | undefined;
  userIsCurrent = false;
  countryService: CountryService;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private _countryService: CountryService
  ) {
    this.profileForm = this.formBuilder.group({
      licenseNumber: ['', Validators.required],
      issuingCountry: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });
    this.countryService = _countryService;
  }

  ngOnInit() {
    this.userDataSubscription = this.authService.cachedUser$.subscribe(user => {
      if (user) {
        this.user = user;
        this.profileForm.patchValue(this.user);
      } else {
        this.authService.GetUserFromFirestore().subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.profileForm.valid && this.user) {
      const updatedUserData = this.profileForm.value;

      this.authService
        .updateUser(updatedUserData)
        .then(() => {
          this.user = { ...this.user, ...updatedUserData };
        })
        .catch(error => {
          console.error('Error updating user data:', error);
        });
    }
  }
}
