import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFlightLog } from 'src/app/models/flight-log.model';
import { LogService } from 'src/app/services/log.service/log.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-flight-form',
  templateUrl: './edit-flight-form.component.html',
  styleUrls: ['./edit-flight-form.component.css']
})
export class EditFlightFormComponent {
  private log!: IFlightLog;
  editForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditFlightFormComponent>,
    public logService: LogService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editForm = new FormGroup({
      selectedCountryTakeOff: new FormControl('', Validators.required),
      selectedMunicipalityTakeOff: new FormControl('', Validators.required),
      selectedCountryLanding: new FormControl('', Validators.required),
      selectedMunicipalityLanding: new FormControl('', Validators.required),
      departureDateTime: new FormControl('', Validators.required),
      departureTime: new FormControl('12:00', Validators.required),
      arrivalDateTime: new FormControl('', Validators.required),
      arrivalTime: new FormControl('12:00', Validators.required),
      balloonRegistration: new FormControl('', Validators.required),
      balloonGroup: new FormControl('', Validators.required),
      balloonType: new FormControl('', Validators.required),
      typeOfOperation: new FormControl('', Validators.required),
      pilotInCommand: new FormControl('', Validators.required),
      amountOfLandings: new FormControl('1', Validators.required),
      pilotType: new FormControl('', Validators.required),
      instructorExaminerName: new FormControl(''),
      extraCommentary: new FormControl(''),
      verified: new FormControl(false)
    });
  }

  ngOnInit() {
    // Set the default values for the form
    this.editForm.controls['selectedCountryTakeOff'].setValue(
      this.data.log.selectedCountryTakeOff
    );

    this.editForm.controls['selectedMunicipalityTakeOff'].setValue(
      this.data.log.selectedMunicipalityTakeOff
    );

    if (this.data.log.departureDateTime instanceof Date) {
      this.editForm.controls['departureDateTime'].setValue(
        this.data.log.departureDateTime
      );
    } else {
      this.editForm.controls['departureDateTime'].setValue(
        this.data.log.departureDateTime.toDate()
      );
    }

    this.editForm.controls['departureTime'].setValue(
      this.data.log.departureTime
    );

    this.editForm.controls['selectedCountryLanding'].setValue(
      this.data.log.selectedCountryLanding
    );

    this.editForm.controls['selectedMunicipalityLanding'].setValue(
      this.data.log.selectedMunicipalityLanding
    );

    if (this.data.log.departureDateTime instanceof Date) {
      this.editForm.controls['arrivalDateTime'].setValue(
        this.data.log.arrivalDateTime
      );
    } else {
      this.editForm.controls['arrivalDateTime'].setValue(
        this.data.log.arrivalDateTime.toDate()
      );
    }

    this.editForm.controls['arrivalTime'].setValue(this.data.log.arrivalTime);

    this.editForm.controls['balloonRegistration'].setValue(
      this.data.log.balloonRegistration
    );

    this.editForm.controls['balloonType'].setValue(this.data.log.balloonType);

    this.editForm.controls['balloonGroup'].setValue(this.data.log.balloonGroup);

    this.editForm.controls['amountOfLandings'].setValue(
      this.data.log.amountOfLandings
    );

    this.editForm.controls['typeOfOperation'].setValue(
      this.data.log.typeOfOperation
    );

    this.editForm.controls['pilotInCommand'].setValue(
      this.data.log.pilotInCommand
    );

    this.editForm.controls['pilotType'].setValue(this.data.log.pilotType);

    this.editForm.controls['extraCommentary'].setValue(
      this.data.log.extraCommentary
    );
  }
  saveForm(): void {
    this.data.log = { ...this.data.log, ...this.editForm.value };

    // Merge departure date and departure time to one datetime
    const departureDate = new Date(
      this.editForm.get('departureDateTime')?.value
    );
    const departureTime = this.editForm.get('departureTime')?.value;
    this.data.log.departureDateTime = this.combineDateAndTime(
      departureDate,
      departureTime
    );

    // Merge arrival date and arrival time to one datetime
    const arrivalDate = new Date(this.editForm.get('arrivalDateTime')?.value);
    const arrivalTime = this.editForm.get('arrivalTime')?.value;
    this.data.log.arrivalDateTime = this.combineDateAndTime(
      arrivalDate,
      arrivalTime
    );

    this.authService.getCurrentUserID().subscribe(userID => {
      if (userID) {
        this.logService.updateLog(this.data.log, userID);
      } else {
        // Handle scenario where no user is logged in
        console.error('not logged in');
      }
    });
    this.dialogRef.close();
  }
  combineDateAndTime(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);

    // Set hours and minutes to the date
    date.setHours(hours, minutes);
    return date;
  }
}
