export interface IBalloon {
  balloonGroup: BalloonGroup;
  balloonType: string;
}

export enum BalloonGroup {
  HotAirA = 'Hot Air - Group A',
  HotAirB = 'Hot Air - Group B',
  HotAirC = 'Hot Air - Group C',
  HotAirD = 'Hot Air - Group D',
  Gas = 'Gas',
  Airship = 'Airship'
}
