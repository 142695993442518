import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LogService } from '../../../services/log.service/log.service';
import { IFlightLog } from 'src/app/models/flight-log.model';
import { IPilot } from 'src/app/models/pilot.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-flight-log-add',
  templateUrl: './flight-log-add.component.html',
  styleUrls: ['./flight-log-add.component.css']
})
export class FlightLogAddComponent {
  formIndex = 0;
  flightForm1: FormGroup;
  flightForm2: FormGroup;
  flightForm3: FormGroup;
  flightForm4: FormGroup;
  flightLog!: IFlightLog;
  pilot!: IPilot;

  constructor(
    private router: Router,
    private logService: LogService,
    private afAuth: AngularFireAuth,
    private authService: AuthService
  ) {
    this.flightForm1 = new FormGroup({
      selectedCountryTakeOff: new FormControl('', Validators.required),
      selectedMunicipalityTakeOff: new FormControl('', Validators.required),
      selectedCountryLanding: new FormControl('', Validators.required),
      selectedMunicipalityLanding: new FormControl('', Validators.required),
      departureDateTime: new FormControl('', Validators.required),
      departureTime: new FormControl('12:00', Validators.required),
      arrivalDateTime: new FormControl('', Validators.required),
      arrivalTime: new FormControl('12:00', Validators.required)
    });
    this.flightForm2 = new FormGroup({
      balloonRegistration: new FormControl('', Validators.required),
      balloonGroup: new FormControl('', Validators.required),
      balloonType: new FormControl('', Validators.required)
    });
    this.flightForm3 = new FormGroup({
      typeOfOperation: new FormControl('', Validators.required),
      pilotInCommand: new FormControl('', Validators.required),
      amountOfLandings: new FormControl('1', Validators.required),
      pilotType: new FormControl('', Validators.required),
      instructorExaminerName: new FormControl('')
    });
    this.flightForm4 = new FormGroup({
      extraCommentary: new FormControl(''),
      verified: new FormControl(false)
    });
  }

  onSubmit() {
    // Merge values from the first form
    this.flightLog = { ...this.flightLog, ...this.flightForm1.value };

    // Merge values from the second form
    this.flightLog = { ...this.flightLog, ...this.flightForm2.value };

    // Merge values from the third form
    this.flightLog = { ...this.flightLog, ...this.flightForm3.value };

    // Merge values from the fourth form
    this.flightLog = { ...this.flightLog, ...this.flightForm4.value };

    // Merge departure date and departure time to one datetime
    const departureDate = new Date(
      this.flightForm1.get('departureDateTime')?.value
    );
    const departureTime = this.flightForm1.get('departureTime')?.value;
    this.flightLog.departureDateTime = this.combineDateAndTime(
      departureDate,
      departureTime
    );

    // Merge arrival date and arrival time to one datetime
    const arrivalDate = new Date(
      this.flightForm1.get('arrivalDateTime')?.value
    );
    const arrivalTime = this.flightForm1.get('arrivalTime')?.value;
    this.flightLog.arrivalDateTime = this.combineDateAndTime(
      arrivalDate,
      arrivalTime
    );

    //Send the log to the database
    this.authService.getCurrentUserID().subscribe(userID => {
      if (userID) {
        this.logService.addFlightLog(this.flightLog, userID);
      } else {
        // Handle scenario where no user is logged in
        console.error('not logged in');
      }
    });
    this.router.navigate(['/logbook']);
  }

  combineDateAndTime(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);

    // Set hours and minutes to the date
    date.setHours(hours, minutes);
    return date;
  }

  nextPage() {
    if (this.isCurrentFormValid()) {
      this.formIndex++;
    }
  }

  previousPage() {
    this.formIndex--;
  }

  // Function to check if the current form is valid
  isCurrentFormValid(): boolean {
    let pilotType;
    switch (this.formIndex) {
      case 0:
        return this.flightForm1.valid;
      case 1:
        return this.flightForm2.valid;
      case 2:
        pilotType = this.flightForm3.get('pilotType')?.value;

        if (pilotType === 'No instructor/examiner') {
          this.flightForm3.get('instructorExaminerName')?.setValidators(null);
        } else {
          this.flightForm3
            .get('instructorExaminerName')
            ?.setValidators([Validators.required]);
        }

        this.flightForm3
          .get('instructorExaminerName')
          ?.updateValueAndValidity();

        return this.flightForm3.valid;
      case 3:
        return this.flightForm4.valid;
      default:
        return false;
    }
  }
}
