<form [formGroup]="flightForm1">
  <div class="section">
    <span>
      <img
        src="\..\..\..\assets\images\flight_takeoff_FILL0_wght400_GRAD0_opsz24.svg"
        alt="alt" />
    </span>
    <h3>Take-off</h3>
  </div>
  <mat-form-field>
    <mat-label for="takeOffCountrySelect"
      >Select the take-off country:</mat-label
    >
    <select
      matNativeControl
      formControlName="selectedCountryTakeOff"
      (change)="onTakeOffCountryChange($event)"
      required
      id="takeOffCountrySelect">
      <option
        *ngFor="let country of countryService.countries | async"
        [value]="country">
        {{ country }}
      </option>
    </select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Enter municipality:</mat-label>
    <input matInput formControlName="selectedMunicipalityTakeOff" required />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Departure date:</mat-label>
    <input
      matInput
      [matDatepicker]="departurePicker"
      formControlName="departureDateTime"
      (ngModelChange)="onArrivalDateChange($event)"
      required />
    <mat-datepicker-toggle
      matSuffix
      [for]="departurePicker"></mat-datepicker-toggle>
    <mat-datepicker #departurePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <label for="departureTime">Departure Time:</label>
    <input
      matInput
      formControlName="departureTime"
      type="time"
      id="departureTime"
      name="departureTime"
      required />
  </mat-form-field>

  <hr class="separator" />

  <div class="section">
    <span>
      <img
        src="\..\..\..\assets\images\flight_land_FILL0_wght400_GRAD0_opsz24.svg"
        alt="alt" />
    </span>
    <h3>Landing</h3>
  </div>
  <mat-form-field>
    <mat-label>Select the landing country:</mat-label>
    <select matNativeControl formControlName="selectedCountryLanding" required>
      <option
        *ngFor="let country of countryService.countries | async"
        [value]="country">
        {{ country }}
      </option>
    </select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Enter municipality:</mat-label>
    <input
      matInput
      formControlName="selectedMunicipalityLanding"
      placeholder="Enter municipality name"
      required />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Arrival date:</mat-label>
    <input
      matInput
      [matDatepicker]="arrivalPicker"
      formControlName="arrivalDateTime" />
    <mat-datepicker-toggle
      matSuffix
      [for]="arrivalPicker"></mat-datepicker-toggle>
    <mat-datepicker #arrivalPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <label for="arrivalTime">Arrival Time:</label>
    <input
      matInput
      formControlName="arrivalTime"
      type="time"
      id="arrivalTime"
      name="arrivalTime"
      required />
  </mat-form-field>
</form>
