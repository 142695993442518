<mat-toolbar class="toolbar">
  <button mat-icon-button [routerLink]="['/logbook']">
    <mat-icon>close</mat-icon>
  </button>
  <span class="toolbar-title">Add flight log</span>
</mat-toolbar>
<div class="main-form">
  <form class="form-container">
    <app-add-flight-location
      [flightForm1]="flightForm1"
      *ngIf="formIndex === 0"></app-add-flight-location>
    <app-add-flight-balloondetails
      [flightForm]="flightForm2"
      *ngIf="formIndex === 1"></app-add-flight-balloondetails>
    <app-add-flight-flightdetails
      [flightForm]="flightForm3"
      *ngIf="formIndex === 2"></app-add-flight-flightdetails>
    <app-add-flight-commentary
      [flightForm]="flightForm4"
      *ngIf="formIndex === 3"></app-add-flight-commentary>
  </form>
  <div class="button-container">
    <button
      mat-raised-button
      [disabled]="formIndex === 0"
      (click)="previousPage()"
      class="back-button">
      Back
    </button>
    <button
      mat-raised-button
      *ngIf="formIndex !== 3"
      [disabled]="!isCurrentFormValid()"
      (click)="nextPage()"
      class="next-button">
      Next
    </button>
    <button
      mat-raised-button
      *ngIf="formIndex === 3"
      (click)="onSubmit()"
      class="submit-button">
      Save log
    </button>
  </div>
</div>
