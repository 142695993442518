<mat-toolbar class="navbar-container">
  <button mat-icon-button routerLink="/home">
    <mat-icon>home</mat-icon>
  </button>
  <button mat-icon-button routerLink="/logbook">
    <mat-icon>library_books</mat-icon>
  </button>
  <button mat-icon-button routerLink="/notifications">
    <mat-icon>notifications</mat-icon>
  </button>
  <button mat-icon-button routerLink="/profile">
    <mat-icon>account_circle</mat-icon>
  </button>
</mat-toolbar>
